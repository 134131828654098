<template>
  <MainLayout>
    <template v-slot:navbar-btn-wrap> </template>
    <template v-slot:default>
      <TableFilters :queries="queries">
        <template v-slot:filter-title>
          <span v-if="sourceObject.count">
            <span class="title-filter">Всего:</span>{{ sourceObject.count }}
            <!--            <span>прошивок</span>-->
          </span>
        </template>
        <template v-slot:add-button>
          <router-link to="createFirmware" class="btn btn-add btn_color_green">
            Добавить прошивку
          </router-link>
        </template>
      </TableFilters>
      <TableLayout
        :loading="isDateLoaded"
        :table-data="sourceObject.results"
        :header="header"
        :source-object="sourceObject"
      />
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import TableFilters from "@/components/tables/TableFilters";
import tableColsFirmwares from "@/helpers/tableCols/firmwares";

export default {
  name: "Firmwares",
  data() {
    return {
      queries: query.firmwares
    };
  },
  components: { TableFilters, TableLayout, MainLayout },
  computed: {
    sourceObject() {
      return this.$store.getters.getFirmwaresList;
    },
    urlQuery() {
      return {
        query: this.$route.query
      };
    },
    header() {
      return tableColsFirmwares;
    }
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      query: { ...this.urlQuery.query, is_test: "false" }
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchPageFirmwaresList").finally(() => {
      this.isDateLoaded = false;
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      this.$store.commit("setSearchQuery", {
        query: {
          ...to.query
          // type: this.type
        }
      });

      this.isDateLoaded = true;
      this.$store.dispatch("fetchPageFirmwaresList").finally(() => {
        this.isDateLoaded = false;
      });
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("clearFirmwaresListPage").then(() => next());
  },
  methods: {}
};
</script>

<style scoped></style>
